<template>
  <div class="ValidatedSubscription">
    
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="text-center">
            <h1 style="font-size: 26px;"><strong>🥳 Abonnement validé 🥳</strong></h1>
          </CCol>
        </CRow>
        
        <CRow class="mt-3" style="font-size: 18px;">
          <CCol class="text-center">
            <p>
              <strong>Félications, votre abonnement à Eclerk a bien été validé ! 👍</strong>
            </p>
            <p>
              Vous pouvez dès maintenant commencer à utiliser les services d'Eclerk.
            </p>
          </CCol>
        </CRow>
        
        <CRow>
          <CCol md="6">
            <CButton class="mt-3" block shape="pill" color="outline-primary" size="lg" @click="$router.push('/')">
              J'accède à mon tableau de bord
            </CButton>
          </CCol>
          <CCol md="6">
            <CButton class="mt-3" block shape="pill" color="outline-primary" size="lg" @click="$router.push('/aide/presentation')">
              Aide - Premiers pas avec Eclerk
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'ValidatedSubscription',
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: true,
    }
  },
  created: function() {
    setTimeout(() => { this.isLoading = false }, 2000);
  },
}
</script>
